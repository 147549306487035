///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
ピックアップカテゴリ（その他、ホット商品等）
*/
.ec-categoryRole {

  @include container;
  max-width: $container-maxwidth3;

  @include containerMargin;

  display: flex;

  @include media_sphone {
    // スマホ時は左右レイアウトを解除（タイトルのある__contentは非表示にし、スマホ用コンテンツを表示します）
    display: block;
    padding-left: 0;
    padding-right: 0;
  }

  // コンテンツ枠（スマホ時は非表示となります）
  & &__content {
    flex: 0 0 auto;
    width: 33%;

    @include media_sphone {
      display: none;
    }

    @include media_tablet {
      width: 40%;
    }

    // sticky粘着用ラッパー
    .wrap {
      position: sticky;
      top: 45vh;// タイトル部分の上下中央位置を決めます（少しだけ上めとしています）
      height: auto;
      width: 90vw;// タイトル文字をはみ出せたいので幅を拡張します（※100vwとすると横スクロールバーが出てしまうので少し差し引いた形としています。厳密にいえば「100vw - 左右余白」になります）
      max-width: $container-maxwidth3;
      z-index: 2;
    }

    .wrap2 {
      margin-top: 0;

      @include media_tablet {
        margin-top: 0;
      }
    }

    // スライドアイテム
    .item {

      // カレントが外れた時の効果
      &:not(.slick-current) {

        .title {

          &.splitting .char {
            animation: animeCategoryRoleTile-out .7s cubic-bezier(.3, 0, .7, 1) both;
            animation-delay: calc(15ms * var(--char-index));
          }

          @keyframes animeCategoryRoleTile-out {
            to {
              transform: translateY(80%);
              opacity: 0;
            }
          }
        }
      }

      // カレントになった時の効果
      &.slick-current {

        .title {

          // 【参考】https://codepen.io/shshaw/pen/ZZJWGO
          &.splitting .char {
            animation: animeCategoryRoleTile-in .7s cubic-bezier(.3, 0, .7, 1) both;
            animation-delay: calc(15ms * var(--char-index));
          }

          @keyframes animeCategoryRoleTile-in {
            from {
              transform: translateY(80%);
              opacity: 0;
            }
          }

          &:after {
            transform: scaleX(1);
          }
        }
      }
    }

    // タイトル
    .title {
      //display: inline-block;
      font-family: $ff-playfair;
      font-size: 4.2vw;
      font-weight: 400;
      line-height: 1.2;
      margin: 0 0 1em;
      //text-shadow: 0 0 3px rgba(255, 255, 255, 0.2), 0 0 10px rgba(255, 255, 255, 0.3);

      @include media_tablet {
        font-size: 6vw;
      }

      position: relative;

      &:after {
        content: "";
        display: block;
        width: 150px;
        height: 5px;
        background-color: #C8AF75;

        @include media_tablet {
          width: calc(40% - #{$padding-tablet});
          height: 2px;
        }

        position: absolute;
        top: 100%;
        left: 0;

        transform-origin: left bottom;
        transform: scaleX(0);
        transition: 1s;
        transition-delay: .3s;
      }
    }

    // サブタイトル
    .title_sub {
      font-family: $ff-min;
      font-size: 1.46rem;
      line-height: 1.2;
      margin: 1em 0;
      max-width: 33%;
      padding-right: $padding-desktop;// 右余白
      text-align: justify;

      @include media_tablet {
        max-width: 40%;
        padding-right: $padding-tablet;// 右余白
      }
    }

    // 説明文
    .description {
      font-family: $ff-min;
      font-size: 0.93rem;
      line-height: 1.8;
      color: $clrDefaultThin;
      //max-width: 35em;
      max-width: 33%;
      padding-right: $padding-desktop;// 右余白
      text-align: justify;
      margin: 1em 0;

      @include media_tablet {
        max-width: 40%;
        padding-right: $padding-tablet;// 右余白
      }
    }

    // 「Shop Collection」ボタン（基本スタイリングは「_2.1.buttonsize.scss」にて）
    .ec-shopCollectionBtn {
      margin: 0;

      @include media_tablet {
        margin-top: $padding-tablet;
      }

      @include media_desktop {
        margin-top: $padding-desktop;
      }
    }
  }

  // イメージ画像枠
  & &__image {
    flex: 0 0 auto;
    width: 67%;

    @include media_sphone {
      width: 100%;
    }

    @include media_tablet {
      width: 60%;
    }

    .item {

      @include media_sphone {
        margin-top: 0;
      }

      @include media_tablet {
        margin-top: $padding-tablet;
      }

      @include media_desktop {
        margin-top: $padding-desktop;
      }

      &:first-child {
        margin-top: 0;
      }

      // スマホ用コンテンツ（スマホ以外は非表示となります）
      .content_sp {
        display: none;

        @include media_sphone {
          display: block;
          padding: $padding-sphone;
        }

        // タイトル
        .title {
          font-family: $ff-playfair;
          font-size: 2rem;
          font-weight: 400;
          line-height: 1.2;
          margin: 0;
          margin-bottom: $padding-sphone;
          position: relative;

          &:after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: #C8AF75;

            position: absolute;
            top: 100%;
            left: 0;
          }
        }

        // サブタイトル
        .title_sub {
          font-family: $ff-min;
          font-size: 1.2rem;
          line-height: 1.2;
          margin: 1em 0;
        }

        // 説明文
        .description {
          font-family: $ff-min;
          font-size: 0.93rem;
          line-height: 1.8;
          color: #505050;
          margin: 1em 0;
        }

        // 「Shop Collection」ボタン（基本スタイリングは「_2.1.buttonsize.scss」にて）
        .ec-shopCollectionBtn {
          margin: $padding-sphone auto 0;
        }
      }
    }
  }
}



/*
RANKING ランキング

トップページで使用されている「Ranking（ランキング）」ブロックのスタイルです。
*/
.ec-rankingProductsRole {

  @include container;
  max-width: $container-maxwidth3;

  @include containerMargin;

  // 商品リスト
  & &__list {
    counter-reset: number;// ランキング番号の準備（リセット）

    // レイアウト
    @include media_sphone {
      @include shelfLayout(3, 10px, 20px);
    }

    @include media_tablet {
      @include shelfLayout(4, 10px, 30px);
    }

    @include media_desktop {
      @include shelfLayout(5, 30px, 30px);
    }
  }

  // 商品アイテム
  /*
   各商品アイテムにはclass「.ec-productItemRole」を指定しておりこれは商品一覧ページでの商品アイテムの見せ方と同じスタイルになります。
   これをベースに必要なCSSスタイリングを上書きしています。
   */
  .ec-productItemRole {
    // ランキング番号の生成（インクリメント）
    counter-increment: number;
    position: relative;

    // ランキング番号
    &:after {
      content: counter(number);
      display: block;
      font-size: 0.93rem;
      $size: 2em;
      width:       $size;
      height:      $size;
      line-height: $size;
      text-align: center;
      background-color: #888;
      color: #fff;

      position: absolute;
      top: 0;
      left: 0;

      @include media_desktop {
        font-size: 16px;
        $size: 30px;
        width:       $size;
        height:      $size;
        line-height: $size;
      }
    }

    // 1位
    &:nth-child(1):after {
      background-color: #FFBF00;
    }

    // 2位
    &:nth-child(2):after {
      background-color: #ccc;
    }

    // 3位
    &:nth-child(3):after {
      background-color: #B25900;
    }

    @include media_sphone {
      // 3つまでを表示
      &:nth-child(n+4) {
        display: none;
      }
    }

    @include media_tablet {
      // 4つまでを表示
      &:nth-child(n+5) {
        display: none;
      }
    }

    // 商品タイトル
    .ec-productItemRole__title {
      margin: 1em 0 0;
    }

    // 商品価格
    .ec-productItemRole__price {
      margin: .5em 0;
    }
  }
}
