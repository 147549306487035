///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
常駐カートイン

商品詳細ページにおいてカートインフォームのクローンコピーを作り、ページ下部にカートインフォームを常駐させています（clone_cartin.js）。
そのスタイリングになります。
*/
.ec-cloneCartinRole {
  // 背景色
  $bgColor: #333;
  // 文字色
  color: #fff;

  /* 数量カウントの「＋」「-」も白色になってしまうので黒文字に上書きスタイリングします */
  .ec-quantityRole__btn i {
    color: #000;
  }

  /* 黒系背景なのでカートボタンも色を変えておきます */
  .ec-blockBtn--cartin {
    background-color: #d00;
    border-color: #d00;
  }

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $bgColor;
  filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.25));
  z-index: 9;

  @include media_sphone {
    padding: 30px $padding-sphone 20px;
  }

  @include media_tablet {
    padding: 30px $padding-tablet;
  }

  @include media_desktop {
    padding: 30px $padding-desktop;
  }

  /* スタンバイ */
  transform: translate3d(0, calc(100% - 10px), 0);
  transition: .5s;

  /**/
  & &__actions {
    max-width: 590px;
    margin: 0 auto;

    .ec-productRole__cartinRow {
      @include media_sphone {
        /* スマホ時、ページトップボタンと被るので余白を設けます */
        padding: 0 30px;
      }
    }
  }

  /* 開閉ボタン */
  & &__toggleBtn {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%,0);

    background-color: $bgColor;
    padding: 8px 40px 4px;
    line-height: 1;
    cursor: pointer;

    span {
      font-size: 17px;

      &:after {
        display: inline-block;
        font-family: "jeiafont";
        content: "\e92f";
        margin-left: 7px;
        font-size: 1.2em;
        line-height: 1;
        transform: translateY(3px);// 位置微調整
      }
    }

    // 開閉ボタンを台形に見せるための左右装飾
    /*
    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
    }

    &:before {
      border-top: solid 16px transparent;
      border-right: solid 4px $bgColor;
      border-bottom: solid 16px $bgColor;
      border-left: solid 4px transparent;
      right: 100%;
    }

    &:after {
      border-top: solid 16px transparent;
      border-right: solid 4px transparent;
      border-bottom: solid 16px $bgColor;
      border-left: solid 4px $bgColor;
      left: 100%;
    }
    */
  }

  /* CLOSEボタン */
  & &__closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 16px;
    line-height: 1;
    padding: .5em;

    visibility: hidden;
    opacity: 0;
    transition: 1s;
  }

  /* OPEN状態 */
  &.is-active {
    transform: translate3d(0,0,0);

    .ec-cloneCartinRole__closeBtn {
      visibility: visible;
      opacity: 1;
    }
  }
}
