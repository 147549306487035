///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
新着情報アイテム

新着情報アイテム自体の基本スタイリングになります。
*/
.ec-newsItemRole {

  // サムネイル枠
  & &__thumbnail {
  }

  // コンテンツ枠
  & &__content {
  }

  // タイトル
  & &__title {
    //display: inline;//inline-block;
    //vertical-align: bottom;
    font-size: .93rem;
    line-height: 1.2;
    color: $clrDefaultThin;
    margin: .75em 0;

    @include media_desktop {
      font-size: 1.06rem;
    }
  }

  // MOREボタン
  /*
  & &__moreBtn {
    display: inline-block;

    font-size: 0.8rem;
    line-height: 1;
    padding: 0.2rem 0.3rem;
    border: solid 1px #666;
    background-color: #fff;
    margin-left: 1rem;
    cursor: pointer;
  }
  */

  // 日付
  & &__date {
    display: block;
    font-size: 0.93rem;
    line-height: 1.2;
    color: #C8AF75;
    margin: .75em 0;
  }

  // 本文
  & &__description {
  }
}



/*
新着情報

トップページで使用されている新着情報ブロックのスタイルです。
*/
.ec-newsRole {
  @include container;
  max-width: $container-maxwidth3;

  @include containerMargin;

  // .slickGutter（スライドアイテム余白）を上書き調整
  .slickGutter {
    @include media_desktop {
      @include slickGutter(20px);
    }
  }

  // prev,nextボタンの配置を外にはみ出し配置、モバイル時はslickコンテナの右上にまとめて配置、のアローアイコンを細い線で実装するスタイル
  @include slickArrowRecommendStyle_thin(1700px, 45px, #222);

  // 新着情報アイテム
  .ec-newsItemRole {
    cursor: pointer;
  }

  // 新着情報サムネイル
  .ec-newsItemRole__thumbnail {
    @include imageHover;
  }

  // 詳細本文（こちらはサムネイル情報を取得するために使用しますので、本文自体は非表示とします）
  .ec-newsItemRole__description {
    display: none;
  }
}



/*
新着情報詳細ポップアップ

新着情報の詳細（ニュース本文）をポップアップして表示するブロックのスタイリングになります。
ニュース本文はslickスライダーとなっています。
*/
.ec-newsDetailRole {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;

  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: .5s;

  &.is-active {
    visibility: visible;
    opacity: 1;
    z-index: 10009;
  }

  // 詳細本文リスト（表示サイズのスタイリング）
  & &__list {
    width: 80%;
    max-width: 800px;
    background-color: #fff;
    color: #000;

    // prev,nextボタンの配置を外にはみ出し配置、モバイル時はslickコンテナの右上にまとめて配置、のアローアイコンを細い線で実装するスタイル
    @include slickArrowRecommendStyle_thin(1700px, 45px, #fff);

    .ec-slickContainer {

      .slick-list {

        .slick-track {

          .slick-slide {
            padding: 1rem;
            height: auto;
            min-height: 50vh;
            max-height: 80vh;
            overflow-y: auto;
          }
        }
      }
    }
  }

  // 詳細本文
  .ec-newsItemRole__description {
    font-size: 1rem;
    margin-top: 1em;
    padding-top: 1em;
    border-top: solid 1px #ccc;

    // サムネイル情報（サムネイル要素に取り込むための情報なので本文欄の中では非表示とします）
    .news_thumbnail {
      display: none;
    }
  }
}
