///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
退会手続き（フロントページ）

警告アイコンを包括するdiv要素で、Twig内で「style="font-size:100px;text-align:center;"」とインラインCSSが記述されており少々手荒な感じがしますが、
当テンプレートでのCSSスタイリングに影響は微小ととらえTwigは編集せずそのままにしておきます。
*/
.ec-withdrawRole {
  @include container;
  text-align: center;

  .ec-icon {
    line-height: 1rem;// 親要素でインラインCSSでfont-size:100pxされてるので調整

    img {
      width: 60px;
      height: 60px;
    }
  }

  & &__title{
    font-size: 1.5rem;
    margin: 1em 0;
  }

  & &__description{
    font-size: 1.1rem;
  }

  .ec-blockBtn--cancel {
    margin-top: 30px;
  }
}



/*
退会手続き（確認ページ）

フロントページでは「.ec-withdrawRole」がコンテナになっていますが、こちらの確認ページでは「.ec-withdrawConfirmRole」でスタイリングされているものの、
コンテナ要素にはなっておりません。この親要素として「.ec-maypageRole」がつけられておりこちらがコンテナとなっています。
*/
.ec-withdrawConfirmRole {
  text-align: center;

  .ec-icon {
    line-height: 1rem;// 親要素でインラインCSSでfont-size:100pxされてるので調整

    img {
      width: 60px;
      height: auto;
    }
  }

  & &__title{
    font-size: 1.5rem;
    margin: 1em 0;
  }

  & &__description{
    font-size: 1.1rem;
  }

  & &__cancel {
  }

  .ec-blockBtn--cancel {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .ec-blockBtn {
    $bgcolor: $btn-action-bg;//#a80000;
    background-color: $bgcolor;
    border-color: $bgcolor;
  }
}
