///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
その他のボタン

通常のボタンや、アイコンボタン以外のボタンを定義します。
*/



/*
ページトップボタン

ページトップボタンのスタイリングです。
*/
.ec-blockTopBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 22px;
  width: 2.182em;
  height: 2.182em;

  color: #999;
  border: solid 3px #999;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;

  visibility: hidden;
  opacity: 0;
  transition: .3s;

  @include media_sphone {
    font-size: 19px;
    right: 10px;
    bottom: 10px;
  }

  /* ボタン表示 */
  body.scrolled & {
    visibility: visible;
    opacity: 1;
  }

  i {
    transform: translate3d(0,1px,0);
    transition: .5s;
  }

  &:hover {
    filter: brightness(1.2);

    i {
      transform: translate3d(0,-1px,0);
    }
  }
}
