///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
住所一覧

マイページ「お届け先一覧」と、ショッピングページ「お届け先の指定」にて使用

ショッピングページ「お届け先の指定」は、配送情報の「変更」ボタンから入ることができます。
しかしながら、この「お届け先の指定」の選択ボタンの部位が、マイページ「お届け先一覧」の削除ボタン部位と同じ扱いとなっており、
その包括classが「.ec-addressList__remove」となっているのは非常に気持ち悪い所ですのでTwigの編集ができるのであればそうしたいところ

当テンプレートではそのままですが、レイアウト的に、選択ボタンは左に寄せ、削除ボタンは右に寄せるようにスタイリングしています。
*/
.ec-addressRole {

  /* 新規お届け先を追加ボタンエリア */
  & &__actions{
    margin-bottom: 2em;
  }
}

/* 住所一覧 */
.ec-addressList {

  & &__item {
    display: flex;
    align-items: center;
    @include borderBottom;

    &:first-child {
      @include borderTop;
    }
  }

  /* 削除ボタンエリア、兼、選択ボタンエリア */
  & &__remove {
    flex: 0 0 auto;
    min-width: 3em;
    text-align: center;

    /*
    選択ボタン（ラジオボタン）においては、ここでは.ec-radioがコーディングされておりませんので、javascriptで.ec-radioとlabelを挿入しています。
    また、住所内容にもlabelを挿入して、住所のクリックでもラジオボタンが反応するようにしてあります。
    javascriptで挿入するのは気がひけますが、もし編集可能であればTwig側をほかのフォームページと同等になるように修正してください（EC-CUBE時期バージョンで修正希望）
    */
  }

  /* 住所エリア */
  & &__address{
    flex: 1 1 auto;
    padding: 1em;
    padding-left: 0;

    /*
    住所エリアにlabelはコーディングされておりませんがUI的にあったほうがいいと判断しjavascriptで挿入させています。（「お届け先の指定」ページ）
    */
    label {
      font-weight: normal;

      /* 氏名欄（識別classは振られていないので、やむなく1番目の要素を氏名と識別） */
      > :first-child {
        font-weight: 500;
      }
    }
  }

  /* 変更ボタン（マイページお届け先一覧のみ） */
  & &__action{
    flex: 0 0 auto;
    padding: 0;
  }
}


/*
マイページ「お届け先一覧」用のカスタマイズ
削除ボタンエリアを右端に来るように変更し、削除アイコンは使わずインラインボタンを生成
*/
body#page_mypage_delivery .ec-addressList {

  .ec-addressList__remove {
    order: 3;
    width: auto;
    padding-left: 10px;

    &:after {
      content: "削除";
      // インラインボタン生成と同じプロセス（_2.1.buttonsize.scss）でボタン作成（変更ボタンと見た目が揃うように）
      @include btn-default;
      padding: 0.6em 1em;
      font-weight: normal;
      font-size: 0.9rem;
    }

    .ec-icon {
      display:none;
    }
  }
}
