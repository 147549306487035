///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

※ここで定義される`.ec-cartRole__error`はShopping/alert.twigでも使用されている。
*/
.ec-cartRole {
  @include container;

  // プログレス表示
  & &__progress {
    margin-bottom: 30px;
    @include media_sphone {
      margin-bottom: 20px;
    }

    .ec-progress {
      // 中身本体の「.ec-progress」のパーツスタイリングは_7.3.cart.scssにて
    }
  }

  // エラー表示
  & &__error {
    margin-bottom: 20px;

    .ec-alert-warning {
      // 「.ec-alert-warning」のパーツスタイリングは当scssの下方にて
    }
  }

  // 商品の合計は〇〇円です
  & &__totalText {
    text-align: center;
    margin-bottom: 20px;

    p {
      margin: 0;
    }
  }

  // カート内容
  & &__cart {
    margin: 2em 0;
    // 中身本体の「.ec-cartTable」のパーツスタイリングは当scssの下方にて
  }

  // カートアクション枠
  & &__actions {
    width: 300px;
    margin-left: auto;
    margin-right: 0;

    @include media_sphone {
      width: 100%;
      margin: 0;
    }
  }

  // 合計
  & &__total {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 1em 0 1.5em;
    @include borderBottom;
  }

  // 合計価格
  & &__totalAmount {
    font-size: 1.6rem;
    font-weight: 500;
    //color: $clrRed;
  }

  // レジに進むボタン
  .ec-blockBtn--action {
    margin-bottom: 10px;
  }
}



/*
カートページのコンテナ違反？

cart/index.twig
.ec-cartRoleの中に、さらに.ec-cartRoleが指定されていて2重コンテナになっているのをCSSにて吸収対処

shopping/nonmemberでも同様に「.ec-customerRole」の中に.ec-cartRoleが入っているので対処
*/
.ec-cartRole,
.ec-customerRole {
  
  .ec-cartRole {
    padding: 0;
  }
}



/*
カート商品表示

カート内の商品をを表示するテーブル枠です。

タブレット・デスクトップはtableでレイアウトを組みますが、スマホは.ec-cartRowごとにflexでレイアウトを組んでいます。
*/
.ec-cartTable {

  // タブレット・デスクトップはtableでレイアウト
  width: 100%;
  display: table;
  border-collapse: collapse;// .ec-cartRow(table-row要素)にborderを有効にする

  // スマホはtableではなく、.ec-cartRowごとにflexでレイアウト
  @include media_sphone {
    display: block;
    @include borderTop;
  }
}



/*
カート商品表示：テーブルヘッダ

カート商品表示のテーブルのヘッダです。
タブレット・デスクトップのみで、スマホでは非表示となります。
*/
.ec-cartHeader {

  display: table-row;
  background-color: $cart-bgcolor;

  & &__label {
    display: table-cell;
    font-size: 0.9rem;
    padding: 0.75em 0;
    text-align: center;
    overflow-x: hidden;
    color: $clrDefaultDark;
  }

  @include media_sphone {
    display: none;
  }
}



/*
カート商品単位（共通パーツ）

カート内のアイテムを表示する単位です。

スマホと、タブレット・デスクトップとでレイアウトの組み方を変えてあります。
タブレット・デスクトップではtable行としてのレイアウトですが、スマホはflexを使ってレイアウトを組んでいます。

デバイス事のCSSスタイリングが煩雑ですので、共通パーツをスタイリングしておき、あとはデバイス個別にスタイリングをする流れとなっております。
*/
.ec-cartRow {
  list-style: none;
  margin: 0;
  padding: 0;

  a:hover {
    text-decoration: none;
  }

  /* ■ 削除カラム（デバイス共通） */
  & &__delColumn {

    .ec-icon {
      display: block;
      color: inherit;

      &:before {
        font-family: "jeiafont";
        content: "\e918";
        display: block;
        font-size: 18px;
        width: 1.5em;
        height: 1.5em;
        line-height: 1.5em;
        text-align: center;
        color: $clrDefaultThin;
        border: solid 1px $clrBorderColor;
        border-radius: 50%;
      }

      &:hover:before {
      }

      img {
        display: none;
      }
    }
  }

  /* ■ 商品内容カラム（デバイス共通） */
  & &__contentColumn {
    text-align: left;
  }

  /* 商品画像 */
  & &__img {
    display: inline-block;
    vertical-align: top;

    a {
      @include imageHover;
    }
  }

  /* 商品サマリ */
  & &__summary {
    display: inline-block;
    vertical-align: top;
    padding-left: 1em;

    .ec-cartRow__name {
      /* 規格名をくくる要素はないので、まず規格名称を基準にスタイリングしておいて、
        * それから商品名が格納されるa要素でスタイリングを施す
        */
      font-size: 0.9em;// 規格名称基準
      line-height: 1.2;
      margin-left: 0.5rem;// 少し字下げ
      margin-bottom: .5em;
      
      a {
        display: block;
        color: inherit;
        font-size: 1rem;
        font-weight: 500;
        margin-left: -0.5rem;// 字下げ戻し
        margin-bottom: 0.4em;

        // a要素の直後のbrは不要
        & + br {
          display: none;
        }
      }
    }

    /* 商品価格 */
    .ec-cartRow__unitPrice {
      font-size: 1rem;
      //margin-left: 0.5rem;
    }
  }

  /* ■ 数量カラム（デバイス共通） */
  & &__amountColumn {

    /* 数量表示 */
    .ec-cartRow__amount {
    }

    /* 数量変更ボタンエリア */
    .ec-cartRow__amountUpDown {
      display: inline-flex;
    }

    /* 数量変更ボタン共通 */
    .ec-cartRow__amountUpButton,
    .ec-cartRow__amountDownButton,
    .ec-cartRow__amountDownButtonDisabled {
      display: block;
      width: 36px;
      height: 36px;
      margin-left: 1px;
      color: inherit;

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: $cart-bgcolor;
        color: $clrDefaultDark;
        padding: 5px;
        box-sizing: border-box;

        &:before {
          font-family: "jeiafont";
          content: "\e917";
          font-size: 1.5em;
        }

        img {
          display: none;
        }
      }
    }

    /* 数量プラスボタン */
    .ec-cartRow__amountUpButton {
      &__icon {
        &:before {
          content: "\e916";
        }
      }
    }

    /* ボタン無効 */
    .ec-cartRow__amountDownButtonDisabled {
      .ec-cartRow__amountDownButton__icon {
        color: #bbb;
      }
    }
  }

  /* ■ 小計カラム（デバイス共通） */
  & &__subtotalColumn {
    font-weight: 500;
  }

  // 当テンプレートではスマホ用の要素は不要
  .ec-cartRow__sutbtotalSP {
    display: none;
  }
  .ec-cartRow__amountSP {
    display: none;
  }
}



/*
カート商品単位（スマホ用）

flexを用いてまず、「商品内容カラム」を100%フル幅にして、
改行する形で「数量カラム」と「小計カラム」を左右2列で配置。
削除ボタンはpositionで右上に配置するレイアウトです。
*/
.ec-cartRow {

  @include media_sphone {
    display: flex;
    flex-flow: row wrap;
    font-size: 1rem;

    padding-top: 1em;
    padding-bottom: 1em;
    @include borderBottom;

    position: relative;

    // 数量変更ボタンサイズ、ならびに数量カラム＆小計カラムの高さ
    $height: 36px;

    /* ■ 削除カラム */
    & &__delColumn {
      position: absolute;
      top: 0.5em;//1em;
      right: 0;

      // アイコン部分はデバイス共通スタイリングにて
    }

    /* ■ 商品内容カラム */
    & &__contentColumn {
      order: 1;
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 5px;

      // その他はデバイス共通スタイリングにて
    }

    /* 商品画像 */
    & &__img {
      width: 22vw;
      max-width: 120px;
    }


    /* ■ 数量カラム */
    & &__amountColumn {
      order: 3;
      flex: 1 1 auto;

      height: $height;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      /* 数量表示 */
      .ec-cartRow__amount {
        margin-right: 1em;

        &:before {
          content: "数量";
          display: inline-block;
          margin-right: .5em;
        }
      }

      /* 数量変更ボタンエリア */
      .ec-cartRow__amountUpDown {
      }

      /* 数量変更ボタンサイズ */
      .ec-cartRow__amountUpButton,
      .ec-cartRow__amountDownButton,
      .ec-cartRow__amountDownButtonDisabled {
        width: $height;
        height: $height;
      }

      // その他はデバイス共通スタイリングにて
    }

    /* ■ 小計カラム */
    & &__subtotalColumn {
      order: 4;
      flex: 0 0 auto;
      min-width: 25%;
      padding-left: 1em;

      height: $height;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}



/*
カート商品単位（タブレット・デスクトップ用用）
*/
.ec-cartRow {
  @media only screen and ( min-width: $tablet ) {
    display: table-row;
    font-size: 1rem;

    @include borderBottom;
  
    /* ■ 削除カラム */
    & &__delColumn {
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      width: 5em;//14%;

      .ec-icon {
        display: inline-block;

        &:before {
          border: none;
        }
      }  
      // その他はデバイス共通スタイリングにて
    }

    /* ■ 商品内容カラム */
    & &__contentColumn {
      display: table-cell;
      //vertical-align: middle;

      padding-top: 1em;
      padding-bottom: 1em;
      // その他はデバイス共通スタイリングにて
    }

    /* 商品画像 */
    & &__img {
      width: 100px;
    }

    /* ■ 数量カラム */
    & &__amountColumn {
      text-align: center;
      display: table-cell;
      vertical-align: middle;

      /* 数量表示 */
      .ec-cartRow__amount {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1em;

        &:before {
          content: "数量";
          display: inline-block;
          margin-right: .5em;
        }
      }

      .ec-cartRow__amountUpDown {
        vertical-align: middle;
      }
    }

    /* ■ 小計カラム */
    & &__subtotalColumn {
      text-align: right;
      display: table-cell;
      vertical-align: middle;
    }
  }
}




/*
アラート

カート内の商品に問題があることを示す警告メッセージです。
*/
.ec-alert-warning {
  background-color: $cart-error-bgcolor;
  color: #fff;
  font-size: 1rem;
  padding: 1em;
  text-align: center;

  & &__icon {
    display: inline-block;
    width: 2em;
    height: auto;
    margin-bottom: 0.5em;
  }
}



/*
送料無料等のメッセージ

「送料無料」「あと〇〇円で送料無料になります」のメッセージ

このエリアのclass名が「.ec-cartRole__progress」となっており、これはカートやショッピングフローの
ページ冒頭にある「1-2-3-4」進捗状況を表示する部分と同じclass名称となっています。
コーティング上のミスだと思いますが、本来であればTwig側を編集すべきだと思いますが、
テンプレートとしては極力、必要以外の編集を加えたくないのでCSSで吸収したいと思います。

ただ、メッセージが空の場合でも要素自体は空のまま出力されてしまっているのでこのメッセージ欄の余白は影響を及ぼします。
その前提で上下のパーツの調整が必要となるかもしれません。

もし、Twig側を編集するのでありましたら、こちらのCSSも調整または削除してください。
*/
.ec-cartRole {

  // 正規のプログレス表示と区別するため、隣接要素「+」にて判別
  .ec-cartRole__cart + .ec-cartRole__progress {
    margin: 0;
    padding: 0;

    margin-top: 1em;
    text-align: right;
  }
}



/*
ショッピング完了コンテナ
*/
.ec-cartCompleteRole {
  @include container;
}
