///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
会員登録系

会員登録 に関する Project コンポーネントを定義します。

会員登録ページのみでなく、会員情報を扱いフォームページにて使用されている様子

メモ：
.ec-registerRole__actionsの上マージンについて、入力ページと確認ページとでは「利用規約チェック」の有無の違いがあるので余白の微調整をしたいところだが、
入力ページと確認ページで識別classが付くわけではない様子なので、区別ができない（たぶん）。
Twigを編集すれば可能となるがテンプレートではTwigは極力変更しない考えなので、この件は保留。jsで実装するのもちょっとあれなので。
*/
.ec-registerRole {
  @include container;

  & &__actions {
    //margin: 20px auto;

    //@include media_desktop {
    //  margin-top: 40px;
    //}

    // 会員登録ページの利用規約チェックボックス
    .ec-checkbox {
      margin: 1em 0;
      border: solid 1px $clrBorderColor;
      padding: 1em;

      label {

        label {
          margin: 0;
        }
      }
    }
  }
}

.ec-registerCompleteRole {
  @include container;
}


/*
.ec-registerRole__actions

`.ec-registerRole__actions`は、会員情報系のフォームページ専用のアクションボタンの括り要素という位置づけで、
コンテナ`.ec-registerRole`に属するコンポーネントという設計思想かと思いましたが、実際のところ、
「お問い合わせ」ページのボタン部分にも使われており、しかし「お問い合わせ」ページはコンテナが`.ec-registerRole`ではありません。
どうやら`.ec-registerRole__actions`は、必ずしも`.ec-registerRole`の中に包含されているとは限らないようで、
ほかのさまざまなコンテナの中で使われています。コーディングルールから外れているように思いますが、CSS側で吸収してスタイリングし直しています。

※送信ボタンRole的なものは様々なフォームページで登場しますので、汎用な意味を持たせる意味で「register」とは違う名称を付けるべきかもしれません。

「.ec-registerRole__actions」使用箇所：
* Contact/confirm.twig
* Contact/index.twig
* Entry/confirm.twig
* Entry/index.twig
* Forgot/reset.twig
* Mypage/change.twig
* Mypage/delivery_edit.twig
* Shopping/nonmember.twig
* Shopping/shipping.twig
* Shopping/shipping_edit.twig
* Shopping/shipping_multiple_edit.twig

また、いくつかのページでは「.ec-RegisterRole__actions」と「r」が大文字「R」になっており、これはおそらくコーディングミスだと思われますが、
これもCSS側で吸収しておきます。（Twigの必要以外の編集はテンプレート的には極力避けたい所です）
※4.2.0でも「R」大文字はまだ残ったままとなっています。

大文字R該当ページ：

* Contact/confirm.twig
* Contact/index.twig
* Mypage/change.twig
* Mypage/delivery_edit.twig
* Shopping/nonmember.twig
* Shopping/shipping_edit.twig
*/
.ec-registerRole,
.ec-mypageRole,
.ec-contactRole,
.ec-contactConfirmRole,
.ec-customerRole {

  .ec-registerRole__actions,
  .ec-RegisterRole__actions {
    margin-top: $padding-sphone;

    @include media_tablet {
      margin-top: $padding-tablet;
    }

    @include media_desktop {
      margin-top: $padding-desktop;
    }

    // 送信ボタン
    .ec-blockBtn--action {
      margin-bottom: 1rem;
    }
  }
}



/*
お届け先の複数指定
shopping_multiple.twig
4.1.2以前はレジスタボタンの外側に「.ec-off4Grid .ec-off4Grid__cell」で包括されていましたが、4.2.0ではその外枠要素が削除されました。
これによりレジスタボタンがコンテナ幅いっぱいになってしまうので、体裁を整えるため調整を加えています。
*/
body#page_shopping_shipping_multiple {

  .ec-AddAddress__actions {
    @include media_desktop {
      @include makeSmColumn(4);//width: 33.3333%
      margin-left: auto;
      margin-right: auto;
    }
  }
}



/*
コンテナ違反？

`.ec-registerRole`は「@include container」されて「コンテナ」として振舞いますが（EC-CUBE標準仕様）、
「お届け先変更」ページ（shipping_edit.twig）では、同じコンテナの「.ec-role」の中に包含される形でコーディングされており、
結果、左右余白が2重にかかってしまいます。
会員ログイン時では2重コンテナにはなっておらずコンテナの住み分けができているので、こちらはコーディングミスではないかと思われます。
テンプレートとしてはTwig編集は極力避けたいのでCSS側にて吸収します。
*/
.ec-role {

  .ec-registerRole {
    padding-left: 0;
    padding-right: 0;
  }
}
