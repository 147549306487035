///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////


/*
Locations 所在地（Googleマップ埋め込み）
*/
.ec-shopMapRole {

  @include containerMargin;

  // コンテナ
  & &__container {

    // デスクトップ時はこちらに背景画像を挿入します
    @include media_desktop {
      padding: 3vw;
      // background-imageはTwig側で指定します
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  // ラッパー
  & &__wrap {
    width: 100%;
    max-width: $container-maxwidth3;
    margin: 0 auto;

    // デスクトップ時は左右レイアウト
    @include media_desktop {
      display: flex;
    }
  }

  // コンテンツ枠
  & &__content {

    @include media_sphone {
      padding: $padding-sphone;
      // 背景画像
      // background-imageはTwig側で指定します
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    @include media_tablet {
      padding: $padding-tablet;
      // 背景画像
      // background-imageはTwig側で指定します
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    
    @include media_desktop {
      // 背景画像は使いませんので除去とします
      background: none !important;

      flex: 0 0 auto;
      width: 50%;
      order: 1;

      // 中央配置
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }

    // ロゴ
    .logo {
      text-align: center;

      img {
        width: auto;
        max-width: none;
      }
    }

    // 住所
    address {
      font-size: .93rem;
      line-height: 2;
      color: #fff;
      text-align: center;
      margin: 1em 0 0;
    }
  }

  // 地図表示枠
  & &__map {

    @include media_desktop {
      flex: 0 0 auto;
      width: 50%;
      order: 2;
    }

    // 地図
    .map {
      width: 100%;
      padding-top: 73%;// 縦サイズ
      position: relative;

      iframe {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
