///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
404ページ

404 エラー画面で使用するページコンポーネントです。

警告アイコンを包括するdiv要素で、Twig内で「style="font-size:100px;text-align:center;"」とインラインCSSが記述されており少々手荒な感じがしますが、
当テンプレートでのCSSスタイリングに影響は微小ととらえTwigは編集せずそのままにしておきます。
*/
.ec-404Role {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100svh;// Chrome-108から対応予定
  //min-height: 100vh;

  //
  .ec-off4Grid {
    @include container;
    width: 100% !important;
    max-width: none;
    @include containerMargin;
  }

  .ec-icon {
    line-height: 1rem;// 親要素でインラインCSSでfont-size:100pxされてるので調整

    img {
      width: 60px;
      height: auto;
    }
  }

  & &__title{
    font-size: 1.5rem;
    margin: 1em 0;
    text-align: center !important;
  }

  & &__description{
    font-size: 1.1rem;
    text-align: center !important;
  }

  .ec-blockBtn--cancel {
    margin-top: 30px;
    width:100%;
    box-sizing:border-box;
  }
}



/*
〇〇は存在しません

商品一覧ページにて、存在しないカテゴリ、商品検索で該当商品0の場合の表示スタイリングです。
使用箇所は商品一覧ページのみですが、拡張的にFAQや他のコンテンツで使用する場面も出てくる可能性もありますので、
こちらにてスタイリングを記述しています。
*/
.ec-notFoundRole {
  @include container;

  p {
    text-align: center;
    margin: 3em 0;
  }
}
