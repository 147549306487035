///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
ヘッドインフォメーション

ヘッダーの上に表示されるインフォメーション部分のスタイリングになります。
*/
.ec-headInfomationRole {
  background-color: $clrHeadInfomationBg;
  color: $clrHeadInfomationText;
  font-size: $HeadInfomationFontSize;//.86rem;
  line-height: 1.2;
  padding: .75em 1em;
  padding-right: 2.5em;
  text-align: center;
  position: relative;

  transition: .3s;

  // ドロワーOPEN時について
  // ドロワー自体の大きさ（高さ）・位置はブラウザ高さとヘッダー高さから算出していますが、ヘッドインフォメーションがあるとドロワー位置が追随できないのでドロワーOPEN時はヘッドインフォメーションを非表示にします。
  @at-root body.have_curtain & {
    @include animateHidden();
  }

  // 非表示状態
  &.jeia_hidden {
    @include animateHidden(600);
  }

  //
  p {
    margin: 0;
  }

  // 閉じるボタン
  & &__close {
    position: absolute;
    top: 50%;
    right: .5em;
    font-size: 1.2rem;
    transform: translate(0,-50%);
    cursor: pointer;
  }
}
