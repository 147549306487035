///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
ECCUBE 固有のスタイルユーティリティ

_projects.scss

* @mixin borderTop
* @mixin borderBottom
* @mixin reset_link

borderTop, borderBottomは、サイト内で一貫したborder線を敷くために使います。
一応、引数で色も指定できますが、一貫とは別種のborderなら直接borderプロパティでスタイリングしたほうがいいとは思います。

reset_linkは、.ec-link（リンクテキストの表現）をリセットさせる目的のmixinでしょうか。当テンプレートではその場その場で、
同等のスタイリングをしていますので、結果このmixinは使用していません。
*/
@mixin borderTop($borderColor: $clrBorderColor) {
  border-top: 1px solid $borderColor;
}

@mixin borderBottom($borderColor: $clrBorderColor) {
  border-bottom: 1px solid $borderColor;
}

@mixin reset_link(){
  a{
    color: inherit;
    text-decoration: none;
  }

  a:hover{
    text-decoration: none;
  }
}

/* 以下はEC-CUBE4.2.0標準に記述されているコードですが、当テンプレートでは「_form.scss」に準備したものがあるのでこちらは使用しません。
@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &:focus {
    border-color: $color;
    outline: 0;
    @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $color-rgba);
  }
}
*/

/* 以下はEC-CUBE4.2.0標準に記述されているコードですが、当テンプレートでは当「_project.scss」に準備したものがあるのでこちらは使用しません。
@mixin tab-focus() {
  // WebKit-specific. Other browsers will keep their default outline style.
  // (Initially tried to also force default via `outline: initial`,
  // but that seems to erroneously remove the outline in Firefox altogether.)
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
*/

/* 以下はEC-CUBE4.2.0標準に記述されているコードですが、当テンプレートでは使用していません。
@mixin opacity($opacity) {
  $opacity-ie: ($opacity * 100);  // IE8 filter
  filter: alpha(opacity=$opacity-ie);
  opacity: $opacity;
}
*/







/*
追加mixin

当テンプレート用にmixinを追加しています。
*/



/*
offGridを無効にするmixin

テンプレート上、レイアウト的に幅狭め（offGird）を無効にしたい場合に適用

※offGrid自体の機能は崩さずそのまま残しておきたいので、部分的に挙動を無効化するmixinを用意。
※offGrid自体の挙動は変更していないが、タブレット時の挙動は別途追加している。
↓
結果的にoffGridの挙動を変更する形にした
*/
//@mixin disableOffGrid($element) {
//  #{$element} {
//    display: block;
//    width: 100%;
//    margin: 0;
//    padding: 0;
//
//    &__cell {
//      display: block;
//      width: 100%;
//      margin: 0;
//      padding: 0;
//      }
//  }
//}



/*
画像マウスオーバー

マウスオーバーで画像を拡大・明度上げします。
mixinはa要素に適用してください。
*/
@mixin imageHover() {
  display: block;
  overflow: hidden;

  /* HTML構造によってはIE11でちらつく事があるので保険 */
  * {
    overflow: hidden;
  }

  img {
    transition: .6s;
  }

  &:hover {

    img {
      transform: scale(1.1);
      filter: brightness(1.05);
    }
  }
}



/*
画像マウスオーバー（カラーオーバーレイ）

マウスオーバーで画像にカラーフィルターをオーバーレイします。
mixinはa要素に適用してください。

* $color: フィルターの色
*/
@mixin imageHover_colorFilter($color: rgba(255, 145, 0, 0.1)) {
  display: block;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color;
    opacity: 0;
    transition: .3s;
  }

  &:hover {

    &:after {
      opacity: 1;
    }
  }
}



/*
横並びメニューのボタンに短い区切り線を入れる

マイページのメニュー部分に使用しています。

* $length: ボーダー長さ（%のみ）
* $color: ボーダー色
*/
@mixin shortLeftBorder($length: 50%, $color: #ccc) {
  $off_length1: ( math.div( (100% - $length), 2 ) );
  $off_length2: $off_length1 + $length;
  property: $off_length1, $off_length2;

  border-left: solid 1px transparent;
  border-image-width: 0 0 0 1px;
  border-image: linear-gradient(to top,
    transparent $off_length1,
    $color $off_length1,
    $color $off_length2,
    transparent $off_length2);
  border-image-slice: 1;
  transition: .2s;

  // 区切り線を消す条件
  &:first-child,
  &:hover,
  &:hover + li,
  &.active,
  &.active + li {
    border-image: linear-gradient(to top, transparent 0%, transparent 100%);
  }
}



/*
商品一覧等の陳列レイアウト

商品一覧やお気に入り一覧など子アイテム（商品）を陳列レイアウト（縦横に並べる）スタイリングを施します。
横に配置する数、アイテム間の余白を引数で指定できます。

商品詳細の画像ビジュアルナビでも使用しています。

* $amount: 横に配置する数
* $gutter_W: アイテム間の左右余白
* $gutter_h: アイテム間の上下余白
*/
@mixin shelfLayout($amount: 5, $gutter_w: 20px, $gutter_h: 20px) {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-flow: row wrap;

  margin-top: $gutter_h * -1;// 上余白分のネガティブマージン

  > * {
    flex: 0 0 auto;

    $gutterTotal: $gutter_w * ($amount - 1);// トータルガター（5個配置なら、余白×4個）

    //width: calc((100% - 1px - #{$gutterTotal} ) / #{$amount});// ※-1pxしているのはIE11、Edgeでカラム落ちする場合があるので
    width: calc((100% - #{$gutterTotal} ) / #{$amount});
    margin-left: $gutter_w;
    margin-top: $gutter_h;

    // 最左のmargin-leftを除去
    &:nth-child( #{$amount}#{'n+1'} ) {
      margin-left: 0;
    }
  }
}



/*
閉じる・削除ボタン

ヘッダーのカート状況のカート削除ボタン、マイぺージお気に入り削除ボタンなど、画像の上に被さるタイプの丸型の削除系ボタンです。

* $size ボタン自体のサイズを指定
*/
@mixin closeBtnCircle($size: 30px) {
  //border: solid 1px #c00;// チェック確認用

  &:after {
    font-family: "jeiafont";
    content: "\e918";

    display: block;
    width: $size;
    height: $size;
    line-height: $size;
    font-size: $size * 0.7;// 指定サイズに対してアイコンフォントサイズの割合

    text-align: center;
    border-radius: 50%;
    background-color: #fff;
    color: #333;
    border: solid 1px #aaa;
    box-sizing: border-box;
    cursor: pointer;

    transform: translate3d(0,0,0);
    transition: .2s;
  }

  &:hover:after {
    text-decoration: none;
    //transform: rotate(-180deg);
    transform: translate3d(0,1px,0);
    box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.3);
  }

  &:hover {
    text-decoration: none;
  }
}



/*
テキストhoverで下線が伸びるエフェクト

* $color: 下線の色
*/
@mixin hoverBottomLine($color: #000) {
  padding-top: 2px;
  padding-bottom: 2px;
  position: relative;
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: $color;
    position: absolute;
    bottom: 0;
    left: 0;
    transform-origin: left bottom;
    transform: scaleX(0);
    //opacity: 0;
    transition: transform .3s;
  }

  &:hover:after {
    transform: scaleX(1);
  }
}



/*
ユーザビリティ タブフォーカス

タブフォーカスのデザインをスタイリングします。
*/
@mixin tab-focus() {
  outline: none;
  border-color: $input-border-focus;
  box-shadow: 0 0 0 $focusShadowsize $clrInputFocusShadow;
}



/*
ブラウザハック IE11
*/
@mixin hack-ie11() {
  @at-root {
    @media all and (-ms-high-contrast: none) {
      *::-ms-backdrop, & {
        @content;
      }
    }
  }
}



/*
商品アイテム マウスオーバーアクション

商品名称や価格表示を包含する商品情報要素を最初に非表示にしておき、商品画像にマウスオーバーしたときに商品情報をオーバーレイ表示させるスタイリングです。

商品一覧ページで使用している商品アイテム「.ec-productItemRole」（_13.2.shelf.scss）を上書きスタイリングとなりますので、
商品一覧と同じ商品アイテム「.ec-productItemRole」の中でのみ動作します。
デスクトップ時のみ適用されるようにメディアクエリの中で「.ec-productItemRole」要素に対してこのmixinを適用してください。
*/
@mixin productItemHoverAction() {
  position: relative;
  overflow: hidden;

  /* 商品情報枠ブロック */
  .ec-productItemRole__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1em;
    background-color: rgba(255, 255, 255, 0.75);
    color: #000;
    pointer-events: none;
    transition: .4s;
    transform: translate3d(0,100%,0);

    h3 {
      color: inherit;
    }

    // 説明文に行数制限を与えます（おすすめ商品プラグインのコメントも同）
    .ec-productItemRole__description,
    .ec-productItemRole__recommendComment {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
    }

    // 各商品情報の余白を調整
    > * {
      margin: 0 0 10px;
    }

    // 最後の位置する商品情報（商品価格）の下余白を解除します
    > :last-child {
      margin-bottom: 0;
    }
  }

  /* 商品名称 */
  .ec-productItemRole__title {
    opacity: 0;
    transform: translate3d(0,1em,0);
    transition: .4s;
    transition-delay: .25s;
  }

  /* 商品説明 */
  .ec-productItemRole__description {
    opacity: 0;
    transform: translate3d(0,1em,0);
    transition: .4s;
    transition-delay: .35s;
  }

  /* 商品価格 */
  .ec-productItemRole__price {
    opacity: 0;
    transform: translate3d(0,1em,0);
    transition: .4s;
    transition-delay: .35s;

    .ec-price__price {
      font-size: 1rem;
    }
  }

  &:hover {

    .ec-productItemRole__content {
      transform: translate3d(0,0,0);
    }

    .ec-productItemRole__title {
      opacity: 1;
      transform: translate3d(0,0,0);
    }

    .ec-productItemRole__description {
      opacity: 1;
      transform: translate3d(0,0,0);
    }

    .ec-productItemRole__price {
      opacity: 1;
      transform: translate3d(0,0,0);
    }
  }
}



/*
折り畳み非表示
*/
@mixin animateHidden($timer: 300) {
  transition: #{$timer}ms;
  line-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  visibility: hidden;
  opacity: 0;
}
