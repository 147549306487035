///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
slick

トッププロモーションや商品ビジュアル画像、商品リストのスライド表示にはEC-CUBE4標準と同じく「slick」を使用しています。

slickを適用する要素は共通してclass「.ec-slickContainer」を設置してその要素に対して適用させるようにしています。

slick適用要素は、slickの準備ができるまではエリアを非表示としておき、準備が整ってからフェードイン表示としています。
slickの準備が完了するまでは画像が一瞬大きく表示されてしまう事がありますのでそれを見せないための処置です。

メモ：
slickドットとslickアロー（prev,next）と両方適用させると、アローボタンは上下中央よりずれてしまいます。
*/
.ec-slickContainer {
  // slick準備整うまでは非表示
  visibility: hidden;
  opacity: 0;
  filter: blur(5px);// ぼかしエフェクト
  transition: 1s;

  // slick準備完了で表示開始
  &.slick-initialized.is-readiness {
    visibility: visible;
    opacity: 1;
    filter: blur(0);
  }
}



/*
slick用パーツ

slick用パーツの共通スタイリングです。

* prev,nextボタン
* ドットボタン
* slickガター
* キャプション表示
* その他（テンプレートにより）
*/



/*
prev,nextボタン

ボタンを画像に被せて左右に配置するるタイプのスタイリングです。
*/
.slick-slider {
  position: relative;// prev,nextボタン用

  .slick-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    padding: 10px;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    font-size: 2.5rem;
    color: #fff;
    cursor: pointer;

    opacity: .5;
    transition: .2s;

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }

    &:hover {
      opacity: 1;
    }

    i {
      display: block;
    }
  }
}



/*
prev,nextボタンの配置を「おすすめ商品」ブロックのようなslickコンテナの横を左右にはみ出すように上書きスタイリングするミックスインになります。
ブラウザ幅が指定したブレイクポイント未満の場合は、prev・nextボタンをslickコンテナの右上にまとめて配置します。

* $breakPoint: ブレイクポイント
*/
@mixin slickArrowRecommendStyle($breakPoint: 1680px) {
  .slick-slider {

    // ブレイクポイント未満
    @media ( max-width: ($breakPoint - 1px) ) {
      .slick-arrow {
        font-size: 1rem;
        color: #fff;
        background-color: #aaa;
        opacity: 1;

        &.slick-prev,
        &.slick-next {
          top: auto;
          left: auto;
          right: 0;
          bottom: calc(100% + 10px);
          height: auto;
        }

        &.slick-prev {
          transform: translate(calc(-100% - 10px),0);
        }

        &.slick-next {
          transform: translate(0,0);
        }
      }
    }

    // ブレイクポイント以上
    @media ( min-width: $breakPoint ) {
      .slick-arrow {
        //color: #666;

        &.slick-prev,
        &.slick-next {
          height: auto;
        }

        &.slick-prev {
          transform: translate(-100%,-50%);
        }

        &.slick-next {
          transform: translate(100%,-50%);
        }
      }
    }
  }
}

/*
上記の@mixin「slickArrowRecommendStyle」のアローアイコンを細い線で実装するスタイル
デスクトップ（指定ブレイクポイント以上）時のアローアイコンをアイコンフォントではなくCSSのborder線で実装したスタイルになります。
アイコンフォントを用いた場合、サイズを大きくすると線も太くなってしまいますが、細い線を維持したデザインとしたい場合に使用します。CSSのborder線ですので太さは維持できます。
まだ整理しきれていない感もありますが、今のところ上記の@mixinの内容をコピーしてデスクトップ時のスタイルのみを書き換えた形になっています。
*/
@mixin slickArrowRecommendStyle_thin($breakPoint: 1680px, $size: 40px, $arrowColor: #333) {
  .slick-slider {

    // ブレイクポイント未満
    @media ( max-width: ($breakPoint - 1px) ) {
      .slick-arrow {
        font-size: 1rem;
        color: #fff;
        background-color: #aaa;
        opacity: 1;

        &.slick-prev,
        &.slick-next {
          top: auto;
          left: auto;
          right: 0;
          bottom: calc(100% + 10px);
          height: auto;
        }

        &.slick-prev {
          transform: translate(calc(-100% - 10px),0);
        }

        &.slick-next {
          transform: translate(0,0);
        }
      }
    }

    // ブレイクポイント以上
    @media ( min-width: $breakPoint ) {

      .slick-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        
        width: $size !important;
        height: $size !important;
        padding: 0;
        opacity: 1;

        position: absolute;
        top: 50%;
        transform: translateY(-50%) !important;
        z-index: 9;
        cursor: pointer;

        //
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        //
        i {
          display: none;
        }

        &.slick-prev {
          left: auto;
          right: 100%;

          &:after {
            border-left: solid 1px $arrowColor;
            border-bottom: solid 1px $arrowColor;
            transform: rotate(45deg);
          }
        }

        &.slick-next {
          left: 100%;
          right: auto;

          &:after {
            border-top: solid 1px $arrowColor;
            border-right: solid 1px $arrowColor;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}



/*
ドットボタン
*/
// slick-dots用プレースホルダーセレクタ
%slick-dots {
    $size: 8px;

    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    justify-content: center;
    padding-top: $size * 1.5;

    li {
      display: block;
      width: $size;
      height: $size;
      border-radius: 50%;
      background-color: $noactive-color;
      cursor: pointer;

      button {
        display: none;
      }

      &.slick-active {
        background-color: $active-color;
      }

      & + li {
        margin-left: $size * 2;//$size * 0.7;
      }
    }

}
.slick-slider {

  // slick-dotsはプレースホルダーセレクタ「%slick-dots」から@extend
  .slick-dots {
    @extend %slick-dots;
  }
}



/*
領域外配置ドットボタン

slickスライダー生成時のパラメータ「appendDots」を使用して任意のHTML要素にドットボタンを配置した場合のスタイリングです。
通常のドットボタンの場合は、prev,nextボタンの上下位置がスライド画像の上下中央にならないので、対策としてドットボタンを領域外に配置します。
その他、独自のスタイリングを施したい場合などに使用する感じです。
*/
.slick-appendDots {
  /* JEIA4010では各ブロックごとにスタイリングする事になると思いますので共通スタイリングはありません。 */
}



/*
slickガター

slick適用内のアイテム間にガター（間隔）を設けます。

slickを適用する要素（基本は.ec-ec-slickContainer）に併せてclass「slickGutter」を記述しておきます。

※なお、レスポンシブでデバイス事にガター幅を可変できるようにmixin化しています。

メモ：
普通に、.slick-slide要素にマージンを設定し、先祖要素である.slick-listにネガティブマージンを設定して調整します。
しかし、それだけではスライド時に左側にわずかに商品アイテムがはみ出した形で見えてしまいます。これを防ぐ為には、
slick適用の要素（基本は.ec-ec-slickContainer）に「overflow:hidden;」すれば解決しますが、
prev,nectボタンをslick領域の外側に配置したい場合があり（position:absolute;で外に出しています）、overflowだと
この外側に配置したpreve,nextボタンが隠されてしまい見えない事になります。
ですので、ネガティブマージンをかけた.slick-list要素に「clip-path」プロパティを使い表示領域をクリップすることで対応しています。

※IE11、Edgeはclip-path非対応なのでクリップパスは適用されず結果、スライド時に左側が少しはみ出して見えます（2019/09）
*/
@mixin slickGutter($gutter: 10px) {

  .slick-list {
    margin-left: $gutter * -1;// ネガティブマージン
    clip-path: inset( 0 0 0 $gutter );// clip-pathを使ってはみ出す領域をクリップ

    .slick-slide {
      margin-left: $gutter;
    }
  }
}

.slickGutter {
  // ガターを10px
  @include slickGutter(10px);

  // デスクトップ時はガター40px
  @include media_desktop {
    @include slickGutter(40px);
  }
}



/*
slick キャプション表示

slick適用の各アイテムの中に「.caption」要素（説明文）を記述しておくと画像に被さる形でテキストが表示されます。

商品詳細ページでは、slickを適用する画像ビジュアル部分が動的に出力されますのでキャプション要素を追加できませんが、
フリーエリア内にてキャプション要素を記述しておき、javascirptで各キャプションを各画像アイテム内に挿入させて実装しています。
*/
.slick-slider {

  .slick-slide {
    position: relative;

    .caption {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;

      font-size: .933rem;
      color: #000;
      background-color: hsla(0, 0%, 100%, 0.5);
      padding: 1em;
      box-sizing: border-box;

      visibility: hidden;
      opacity: 0;
      transform: translate3d(0,100%,0);
      transition: .5s;
      transition-delay: .5s;
    }

    &.slick-current {

      .caption {
        visibility: visible;
        opacity: 1;
        transform: translate3d(0,0,0);
      }
    }
  }
}





/*
トップページ トッププロモーション
*/
.ec-headPromotionRole {
  overflow: hidden;// コンテンツの__innerが100vwではみ出すので

  display: flex;
  justify-content: space-between;

  @include media_sphone {
    // スマホ時は上下配置にします
    flex-flow: row wrap;
  }

  @include media_tablet {
    // タブレット時は上下配置にします
    flex-flow: row wrap;
  }

  // コンテンツ枠
  & &__content {
    flex: 0 0 auto;
    width: 40%;
    position: relative;

    @include media_sphone {
      width: 100%;
      order: 2;
      position: static;
      padding: $padding-sphone;
    }

    @include media_tablet {
      width: 100%;
      order: 2;
      position: static;
      padding: $padding-tablet;
    }

    // 領域外配置ドット
    .slick-appendDots {
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc((100vw * 0.4) - 40px);// コンテンツエリアが100vwの40%、そこから左余白40pxを差し引いて算出（下記.descriptionと同じ）
      margin-left: 40px;
      margin-bottom: 40px;

      @include media_sphone {
        position: static;
        width: 100%;
        margin: $padding-sphone 0 0;
      }

      @include media_tablet {
        position: static;
        width: 100%;
        margin: $padding-tablet 0 0;
      }

      // slick-dotsはプレースホルダーセレクタ「%slick-dots」から@extend
      .slick-dots {
        @extend %slick-dots;
        padding: 0;
      }
    }

    // Scrollボタン
    .btn_scroll {
      font-size: 14px;

      position: absolute;
      top: 50%;
      left: 0;

      transform-origin: left bottom;
      transform: rotate(90deg);
      margin-left: 4em;

      .content {
        display: flex;
        flex: row nowrap;
        justify-content: center;
        align-items: center;
      }

      .bar {
        display: block;
        width: 5em;
        height: 1px;
        background-color: #ddd;
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 1.2em;
          height: 100%;
          background-color: #222;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .text {
        display: block;
        margin: 0 5px;
      }

      // ある程度ブラウザ幅がある場合のみ表示
      @media (max-width:1800px) {
        display: none;
      }
    }
  }

  // 画像枠（slickスライダー）
  & &__image {
    flex: 0 0 auto;
    width: 60%;

    @include media_sphone {
      width: 100%;
      order: 1;
    }

    @include media_tablet {
      width: 100%;
      order: 1;
    }

    // slickアイテム（画像）
    .item {

      /*
      // カレントが外れる瞬間の効果
      &:not(.slick-current) {
      }
      */

      // カレントになる瞬間の効果
      &.slick-current {

        img {
          animation: animePromoImageOn 2s forwards;
        }

        @keyframes animePromoImageOn {
          0% {
            transform: scale(1.1);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
  }

  // コンテンツインナー
  /*
   * position:absoluteによりプロモーションエリア全体を覆う要素になります。
   * 内容のコンテンツのスタイリングをしやすいようにこちらの要素に左右余白を設けてあります。
   */
  & &__inner {
    position: absolute;
    top:0;
    left: 0;
    width: calc(100vw - 20px);// デスクトップ時スクロールバー分はみ出すのでざっくり差し引きます。左右2分割した中なので100%は使えません。メイン親要素にoverflow:hiddenは指定していますが一応
    height: 100%;
    padding: 0 40px;
    //border: solid 1px #c00;

    z-index: 2;
    pointer-events: none;// プロモーション内のボタンや機能にアクセスできるように

    // flexボックスにて内容コンテンツを中央配置させます
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    @include media_sphone {
      position: static;
      width: 100%;
      height: auto;
      padding: 0;
    }

    @include media_tablet {
      position: static;
      width: 100%;
      height: auto;
      padding: 0;
    }

    // slickコンテナ（テキストコンテンツ）
    .ec-slickContainer {
      width: 100%;
      max-width: 1580px;

      pointer-events: auto;
    }

    // slickアイテム（テキストコンテンツ）
    .item {

      // タイトル
      .title {
        font-family: $ff-playfair;
        font-size: 5.2vw;//6.66rem;
        line-height: 1.1;
        margin: .5em 0;// 上マージンを設けることでインナーエリアをわずかに下方へ下げています
        //text-shadow: 0 0 3px rgba(255, 255, 255, 0.2), 0 0 10px rgba(255, 255, 255, 0.3);

        @include media_sphone {
          font-size: 2rem;
          margin: 0;
          margin-bottom: $padding-sphone;
        }

        @include media_tablet {
          font-size: 6vw;
          margin: 0;
          margin-bottom: $padding-tablet;
        }

        // 文字サイズはブラウザ幅1920px時のデザインに沿ってvwでバランスを維持しますが、表示領域の最大幅が指定されていますのでブラウザ幅が1920px以上になるとバランスが崩れてしまいます。対策として文字サイズの最大値を定義しておきます。
        @media (min-width:1920px) {
          font-size: 100px;
        }
      }

      /*
      // カレントが外れる瞬間の効果
      &:not(.slick-current) {
        .title {
        }
      }
      */

      // カレントになった時の効果
      &.slick-current {

        .title {

          // 【参考】https://codepen.io/shshaw/pen/ZZJWGO
          &.splitting .char {
            animation: animeCategoryRoleTile-in .7s cubic-bezier(.3, 0, .7, 1) both;
            animation-delay: calc(15ms * var(--char-index));
          }

          @keyframes animeHeadPromotionTitle-in {
            from {
              transform: translateY(80%);
              opacity: 0;
            }
          }
        }
      }

      // 説明文
      .description {
        font-family: $ff-min;
        font-size: .93rem;
        line-height: 2.2;
        margin: 2em 0;

        width: calc((100vw * 0.4) - 40px);// コンテンツエリアが100vwの40%、そこから左余白40pxを差し引いて算出
        padding-right: 1em;// さらに一応、右端に1em分の余白を設置

        max-width: 550px;
        text-align: justify;

        @include media_sphone {
          width: 100%;
          max-width: none;
          padding-right: 0;
          line-height: 1.5;
          margin: 1em 0;
        }

        @include media_tablet {
          width: 100%;
          max-width: none;
          padding-right: 0;
          //line-height: 1.5;
          margin: 1em 0;
        }
      }

      // 「Shop Collection」ボタン（基本スタイリングは「_2.1.buttonsize.scss」にて）
      .ec-shopCollectionBtn {

        @include media_sphone {
          margin-left: auto;
          margin-right: auto;
          margin-top: $padding-sphone;
        }

        @include media_tablet {
          margin-left: auto;
          margin-right: auto;
          margin-top: $padding-tablet;
        }
      }
    }
  }
}
