///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
Pick up ピックアップ
*/
.ec-pickupRole {
  @include container;
  max-width: $container-maxwidth3;

  @include containerMargin;

  // .slickGutter（スライドアイテム余白）を上書き調整
  .slickGutter {
    @include media_desktop {
      @include slickGutter(20px);
    }
  }

  // prev,nextボタンの配置を外にはみ出し配置、モバイル時はslickコンテナの右上にまとめて配置、のアローアイコンを細い線で実装するスタイル
  @include slickArrowRecommendStyle_thin(1700px, 45px, #222);

  // 商品一覧
  & &__list {

  }

  // 商品アイテム
  & &__item {

    //
    a {
      display: flex;
      color: inherit;
      transition: .3s;

      // スマホ時は画像とコンテンツを縦配置にします
      @include media_sphone {
        flex-flow: column;
      }

      &:hover {
        text-decoration: none;
        filter: brightness(1.1);

        .image {

          img {
            transform: scale(1.1);
          }
        }
      }
    }

    // content
    .content {
      flex: 0 0 auto;
      width: 54%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding: 1em;

      @include media_sphone {
        width: 100%;
        order: 2;
      }

      // タイトル（商品名）
      .title {
        margin: 0;
        font-size: 1rem;
        font-weight: 100;
        line-height: 1.2;
        color: inherit;

        @include media_desktop {
          font-size: 1.33rem;
        }
      }

      // サブテキスト（カテゴリ名やブランド名等）
      .subtext {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
        margin-top: .5em;

        @include media_desktop {
          font-size: 1.33rem;
        }
      }
    }

    // image
    .image {
      flex: 0 0 auto;
      width: 46%;
      overflow: hidden;

      @include media_sphone {
        width: 100%;
        order: 1;
      }

      img {
        transition: .5s;
      }
    }
  }

  // slick-dotsはプレースホルダーセレクタ「%slick-dots」から@extend
  .slick-dots {
    @extend %slick-dots;
    padding: 0;

    @include media_sphone {
      padding-top: $padding-sphone;
    }

    @include media_tablet {
      padding-top: $padding-tablet;
    }

    @include media_desktop {
      padding-top: $padding-desktop * 1.5;
    }
  }
}



/*
Concept コンセプト
*/
.ec-conceptRole {
  @include containerMargin;

  @include media_desktop {
    margin-bottom: $padding-desktop * 3;
    position: relative;
  }
  
  // コンテンツ
  & &__content {

    @include media_sphone {
      padding: $padding-sphone;
      background-color: $clrBodyBg;
      color: $clrDefault;
    }

    @include media_tablet {
      padding: $padding-tablet;
      background-color: $clrBodyBg;
      color: $clrDefault;
    }
    
    // まず100%幅にしてイメージ画像枠の上に被せます。その際に底配置、左右にコンテナとしての余白を設けておきます。
    @include media_desktop {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      padding: 0 $padding-desktop;
      pointer-events: none;

      // セクションタイトルの上書き
      .ec-secHeading {

        h2 {
          color: $clrDefault;
        }

        .ec-secHeading__en {
          font-size: 1.866rem;
        }
      }
    }
  }

  // コンテンツインナー
  & &__contentInner {

    // 1段目インナーとして、幅はいっぱいに伸ばしますが最大幅1580pxと定義して、他ブロックとの右端面位置を確保します。
    // 2段目インナーが右端に位置するようflexボックスにして定義します。
    @include media_desktop {
      width: 100%;
      max-width: 1580px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
    }
  }

  // コンテンツインナー
  & &__contentInnerInner {

    // 白背景のコンテンツ本体部分となります。
    @include media_desktop {
      width: 25em;
      padding: $padding-desktop;
      pointer-events: auto;

      // アクション部分を外にはみ出させるrelative
      position: relative;

      // デスクトップ時は白地黒文字
      background-color: #fff;
      color: #000;
      .ec-secHeading h2,
      .title,
      .description {
        color: inherit;
      }
    }

    // タイトル
    .title {
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 1.2;
      margin: 0 0 0.5em;
    }

    // 説明文
    .description {
      font-family: $ff-min;
      font-size: .96rem;
      line-height: 1.8;
      color: $clrDefaultThin;
      text-align: justify;
    }
  }

  // コンテンツアクション部
  /*
   * コンテンツインナー2段目に含まれる要素ですが、この部分だけpositionで外にはみ出させるデザインとします
   */
  & &__contentAction {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media_sphone {
      margin-top: $padding-sphone;
    }

    @include media_tablet {
      margin-top: $padding-tablet;
    }

    @include media_desktop {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: $clrBodyBg;
      padding: 1.5rem $padding-desktop;

      justify-content: space-between;
    }

    // slick-dotsはプレースホルダーセレクタ「%slick-dots」から@extend
    .slick-dots {
      @extend %slick-dots;
      padding: 0;

      @include media_desktop {
        justify-content: flex-start;// 左寄せに変更
      }
    }

    // カレント番号
    .current_num {
      font-family: $ff-en;
      font-size: 1.2rem;
      line-height: 1;
      color: $clrDefaultThin;//#505050;
      margin-left: 1em;

      @include media_sphone {
        display: none;
      }
      
      @include media_tablet {
        display: none;
      }
    }
  }

  // イメージ画像
  & &__image {
  }
}
