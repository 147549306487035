///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
レイアウトフレーム

当テンプレートは1カラムデザインとなっています。ここでは2～3カラムレイアウト定義は省いて1カラムデザインのみスタイリングしています。
*/
.ec-layoutRole {
  width: 100%;
  transition: transform 0.3s;
  background: transparent;

  // stickyフッター
  min-height: 100vh;
  display: flex;
  flex-flow: column;

  & &__contentTop {
    padding: 0;
  }

  & &__contents {
    width: 100%;
  }

  & &__main {
    width: 100%;
  }

  // フッター（※フッターのスタイリングは「_11.3.footer.scss」にて）
  & &__footer {

    // stickyフッター
    margin-top: auto;
    margin-bottom: 0;
  }
}



/*
レイアウトフレーム（2～3カラム）

当テンプレートは1カラムデザインとなっており、2～3カラムレイアウトは使っておりませんが、
別レイアウトの追加や、またカスタマイズが可能なように定義は準備してあります。

左カラム・右カラムに配置されたブロックは、EC-CUBE4ではモバイル時は（スマホ・タブレット時）非表示扱いのようですが、
当テンプレートではメインフレームの下に配置するようにしてあります。
*/
/*
.ec-layoutRole {

  @include media_sphone {
    & &__contents {
      display: flex;
      flex-flow: row wrap;
    }

    & &__mainWithColumn,
    & &__mainBetweenColumn {
      order: 1;
      width: 100%;
      max-width: 100%;
    }

    & &__left,
    & &__right {
      order: 2;
      width: 100%;
      max-width: 100%;
      margin-top: $padding-sphone;
      padding-left: $padding-sphone;
      padding-right: $padding-sphone;
    }

    & &__right {
      order: 3;
    }
  }

  @include media_tablet {
    & &__contents {
      display: flex;
      flex-flow: row wrap;
    }

    & &__mainWithColumn,
    & &__mainBetweenColumn {
      order: 1;
      width: 100%;
      max-width: 100%;
    }

    & &__left,
    & &__right {
      order: 2;
      width: 100%;
      max-width: 100%;
      margin-top: $padding-tablet;
      padding-left: $padding-tablet;
      padding-right: $padding-tablet;
    }

    & &__right {
      order: 3;
    }
  }

  @include media_desktop {
    & &__contents {
      display: flex;
      flex-flow: row wrap;
    }

    & &__mainWithColumn {
      order: 2;
      width: 75%;
      max-width: 75%;// for IE11
    }

    & &__mainBetweenColumn {
      order: 2;
      width: 50%;
      max-width: 50%;// for IE11
    }

    & &__left {
      order: 1;
      flex: 0 0 25%;
      max-width: 25%;// for IE11

      box-sizing: border-box;
      padding-left: $padding-desktop;
    }

    & &__right {
      order: 3;
      flex: 0 0 25%;
      max-width: 25%;// for IE11

      box-sizing: border-box;
      padding-right: $padding-desktop;
    }
  }
}
*/



/*
汎用コンテナ

汎用的なコンテナブロックです。

mixin「コンテナ」を@includeしています。
mixin「コンテナ」は、コンテナ自体のmax-width、左右余白などブロックスタイルの共通スタイリングを施しています。
各セクションの左右余白の統一感はコンテナによってもたらされます。

背景色をブラウザ幅フルに敷く場合は「max-width:100%;」上書きしておいて、コンテナの中にinner的要素を設けてそちらで改めてmax-widthを指定します。
*/
.ec-role {
  @include container;
}



/*
マイページコンテナ

マイページ専用のコンテナブロックです。

※当テンプレートでは汎用コンテナと同一です。
*/
.ec-mypageRole {
  @include container;
}



/*
その他コンテナ

その都度、セクションごとに「@include container;」してコンテナ化させています。
上記、マイページコンテナもマイページ定義のscss側に移動したほうがいいかとも思います。
*/
