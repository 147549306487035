///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
リスト

シンプルなリストを構成するためのスタイル群です。
*/



/*
水平定義リスト

シンプルな定義リストのスタイルを定義します。

dl要素を用いてコーディングします。
*/
.ec-definitions {
  margin: 0.5em 0;
  display: block;

  & dt, dd {
    display: inline-block;
    margin: 0;
  }

  & dt {
    font-weight: 500;
    min-width: 8em;
    margin-right: 1em;
  }
}

// ※使用先：注文履歴詳細の配送情報内、配送方法、お届け日時の部分
.ec-definitions--soft {
  @extend .ec-definitions;

  & dt {
    font-weight: normal;
  }
}



/*
定義リスト

線が添えられた定義リストのスタイルを定義します。（dlリスト）

フォーム入力ページや、「当サイトについて」「特商表記」など、タイトルと本文のあるコンテンツのスタイル。

メモ：
dtの中では「.ec-label」とセットで使われるのが常のようですが、これはフォームページの名残ともとれますが（違うかもしれませんが）、
「.ec-label」という名前上、フォーム周りのタイトルという先入観が拭えませんのでいささか気になりますが、当定義リストのdt要素が、
あくまでレイアウト役割のみという要素で割り切るなら、見た目のスタイルを当てている「.ec-label」は汎用的ならラベル要素とも取れます。
（ただ、_3.3.form.scssの中で定義されているのでやはり本来はフォームラベルの目的でコーディングされたような気がします）
*/
.ec-borderedDefs {

  dl {
    width: 100%;
    margin: 0;
  }

  dt {
  }

  dd {
  }

  // スマホ
  @include media_sphone {

    dl {
      padding: 0.5em 0;
    }
  
    dt {
      display: block;
      padding-bottom: 0.5em;
      @include borderBottom;
    }
  
    dd {
      display: block;
      padding: 1rem 0;
    }    
  }

  // タブレット
  @include media_tablet {
    
    dl {
      display: table;
      @include borderBottom;
    }
  
    dt {
      display: table-cell;
      width: 30%;
      vertical-align: middle;  
    }
  
    dd {
      display: table-cell;
      width: auto;
      vertical-align: middle;
      padding: 1.5rem 0;  
    }
  }
  
  // デスクトップ
  @include media_desktop {
    dl {
      display: table;
      @include borderBottom;

      &:first-child {
        @include borderTop;
      }
    }
  
    dt {
      display: table-cell;
      width: 30%;
      vertical-align: middle;  
    }
  
    dd {
      display: table-cell;
      width: auto;
      vertical-align: middle;
      padding: 1.5rem 0;  
    }      
  }
}



/*
ボーダーリスト

線が添えられたリストを表示します。

使用場面はショッピングページのカート商品内容部分で、その中身は商品画像と商品詳細の「.ec-imageGrid」（_5.2.layout.scss）が入ります。

「.ec-borderedLis」も「.ec-imageGrid」もそのパターンでしか使われていない様子なので、
まとめて一つのコンポーネントにしても良いかとも思いますが、今のところはそのままにします。

それともう一点、
「.ec-imageGrid」の使用場面は先のショッピングページの商品内容と、マイページ注文履歴詳細の商品内容とになりますが、
ショッピングページではこれをリスト化（ul li要素）して括っているのが「.ec-borderedList」であるのに対して、
注文履歴詳細のほうではそのリストが含まれておりません。

どちらもさらにその外側で「.ec-orderDelivery__item」で括っているのですが、さらに、
ショッピングページ側では、「.ec-orderDelivery__item」は1つのみでその中にリストのul要素があり、商品ごとにli要素で
括っているのに対して、注文履歴詳細はリストが無く、商品ごとに「.ec-orderDelivery__item」で括っているという違いがあります。

意味合い的にもスタイリングにしても同一なものなので統一してもらいたい所ですが、今のところはCSSで統一させるに留めます。

まず、「.ec-orderDelivery__item」には何もスタイリングしなようにしておき、注文履歴詳細ページのみに働くように
「.ec-orderDelivery__item」に「.ec-borderedList」と同じ形のスタイリングを施しています。

〇ショッピングページ側
構成（※スタイルガイドページでは崩れますのでscssファイルでご覧ください）
.ec-orderDelivery__item
  > ul.ec-borderedList
    > li > .ec-imageGrid
    > li > .ec-imageGrid
    > li > .ec-imageGrid


〇注文履歴詳細ページ側
構成（※スタイルガイドページでは崩れますのでscssファイルでご覧ください）
.ec-orderDelivery__item > .ec-imageGrid
.ec-orderDelivery__item > .ec-imageGrid
.ec-orderDelivery__item > .ec-imageGrid
*/
.ec-borderedList {
  list-style: none;
  margin: 0;
  padding: 0;

  @include borderTop;

  li {
    padding: 10px 0;
    @include borderBottom;
  }
}

/*
注文履歴詳細用
本来は_15.2.order.scssにてスタイリングすべきですが（※これも厳密にいえばショッピングとマイページコンテンツとで分けておくべきかとも思いますが）、
上記のメモ書きの理由により、混雑しないようにこちらで「.ec-borderedList」と合わせてスタイリングします。
*/
body#page_mypage_history {
  .ec-orderDelivery__item {
    padding: 10px 0;
    @include borderTop;
    @include borderBottom;
    
    & + .ec-orderDelivery__item {
      border-top: none;
    }
  }
}
