///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
文字装飾

文字装飾をするためのスタイル群です。
*/



/*
テキストリンク

テキストリンクのスタイルです。

※ログインページ（Mypage,Shopping）では上書き調整しておりますのでこちらのスタイリングは反映されない形です。
*/
.ec-link {
  //color: #0092C4;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    //color: #33A8D0;
    text-decoration: none;
  }
}



/*
テキスト（太字）

テキストを太くするためのスタイルです。
*/
.ec-font-bold {
  font-weight: 500;
}



/*
テキスト（グレー）（未使用）

テキストをグレーにするためのスタイルです。
*/
.ec-color-grey {
  color: gray;//$clrDefaultThin;
}



/*
テキスト（赤）

テキストを赤にするためのスタイルです。
*/
.ec-color-red {
  color: $clrRed;
}

.ec-color-accent {
  color: $clrRed;
}



/*
フォントサイズ（未使用）

フォントサイズを指定するためのスタイルです。
EC-CUBE4ではカートブロックで使用しているようですが、当テンプレートでは未使用です。
*/
.ec-font-size-1 {
  font-size: 12px;
}

.ec-font-size-2 {
  font-size: 14px;
}

.ec-font-size-3 {
  font-size: 16px;
}

.ec-font-size-4 {
  font-size: 20px;
}

.ec-font-size-5 {
  font-size: 32px;
}

.ec-font-size-6 {
  font-size: 40px;
}



/*
テキスト水平位置

テキストをセンタリングするためのスタイルです。
*/
.ec-text-ac {
  text-align: center;
}



/*
価格テキスト

価格表示を構成するスタイリングです。
構成としては、「通常価格」「販売価格」のラベルテキスト、価格部分、「税込」のテキスト、になります。
ここでは各テキスト部位をインラインで配置するスタイリングに留めています。
各テキスト部位ごとのフォントサイズ調整は、「_13.2.shelf.scss」「_14.1.product.scss」側にてスタイリングします。
「販売価格」と「通常価格」で価格部分のフォントサイズは変化をつけたいが、ラベルと税込みテキストは合わせておきたい等の要望はあるかと思い、
そのようにしたほうが調整がしやすいと判断しました。
*/
.ec-price {

  /* 「通常価格」「販売価格」のラベルテキスト */
  & &__label {
    display: inline-block;
    vertical-align: baseline;
    margin-right: 0.5em;
  }

  /* 価格本体 */
  & &__price {
    //font-family: $ff-en_thin;
    display: inline-block;
    vertical-align: baseline;
  }

  /* 「税込」のテキスト */
  & &__tax {
    display: inline-block;
    vertical-align: baseline;
  }
}



/*
テキストの位置

テキストや、入れ子にしたインライン要素を「左揃え」「中央揃え」「右揃え」に設定することができます。
*/
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}



/*
メッセージテキスト

ユーザが行った操作に対する、完了報告やエラー表示のページで使用されるテキストのスタイルです。
*/
.ec-reportDescription {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 2em;
  text-align: left;

  @include media_desktop {
    text-align: center;
  }
}



/*
テキスト下部のスペース

テキストの下に余白を追加することができます。
*/
.ec-para-normal {
  margin-bottom: 1em;
}



/*
箇条書きスタイル

利用ガイド等で使用する箇条書きスタイルです。各リストマークのパターンを用意しています。
箇条書きスタイルはdl要素に対して適用してください。
*/
/* 箇条書き：ベーシック */
.ec-list {
  margin: 1em 0;

  dt {
    font-weight: 700;
  }

  > dd {
    line-height: 1.8;
    position: relative;
    padding-left: 1.3em;/* マークとテキストの間隔調整 */

    &:before {
      content: "・";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

/* 箇条書き：「※」スタイル */
.ec-list-rice {
  @extend .ec-list;

  > dd:before {
    content: "※";
  }
}

/* 箇条書き：「●」スタイル */
.ec-list-disc {
  @extend .ec-list;

  > dd:before {
    content: "●";
    color: $clrDefaultThin;
  }
}

/* 箇条書き：連番スタイル */
.ec-list-decimal {
  @extend .ec-list;

  > dd {
    counter-increment: note;

    &:before {
      content: counter(note)".";
    }
  }
}

/* 箇条書き：アイコンスタイル */
.ec-list-icon {
  @extend .ec-list;

  > dd:before {
    font-family: "jeiafont";
    content: "\e91a";// jeia-caret
    color: #aaa;
  }
}
