///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
関連商品管理プラグイン

関連商品プラグインはTwigファイルをカスタマイズできません（プラグイン有功時にフロントにTwigファイルがコピーされない様子）ので、
プラグインが持つオリジナルのTwigのHTML構造の形で、可能な範囲においてスタイリングを施しています。
Twigをカスタマイズされる場合は「/app/Plugin/RelatedProduct4/Resource/template/front/related_product.twig」を編集してください。
*/
#RelatedProduct-product_area {

  @include container;
  max-width: $container-maxwidth2;// 商品詳細ページのmax-widthと同じにします

  @include containerMargin;

  // 商品一覧
  .ec-shelfGrid {

    // 商品アイテム
    .ec-shelfGrid__item {

      a {
        color: inherit;

        &:hover {
          text-decoration: none;
        }
      }

      p {
        text-align: center;
        margin-bottom: .5em;
      }

      // 商品画像
      .ec-shelfGrid__item-image {
        @include imageHover;
        margin-bottom: 1em;
      }

      // 関連商品コメント
      a + span {
        font-size: .8em;
        line-height: 1.2;
        text-align: left;
      }
    }
  }
}
