///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
カレンダー

※EC-CUBE4.1.1より追加された「カレンダーブロック」になりますが、当テンプレートではフッターカレンダーとして使用しています。

フッターカレンダーのCSSスタイリングは「_11.3.footer.scss」で行っています。
*/
