///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
カート追加モーダル

カート追加モーダルに関する Project コンポーネントを定義します。
*/
.ec-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;

  &.small {
    width: 30%;
  }

  &.full {
    width: 100%;
    height: 100%;
  }

  .ec-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
  }

  .ec-modal-wrap {
    position: relative;
    border-radius: 2px;
    border: 1px solid #333;
    background-color: #fff;
    color: #000;
    width: 90%;
    margin: 20px;
    padding: 40px 5px;
    @include media_desktop {
      padding: 40px 10px;
      width: 50%;
      margin: 20px auto;
    }
  }

  .ec-modal-close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 20px;
    height: 30px;
    width: 20px;
    &:hover {
      color: #4b5361;
    }
  }

  .ec-modal-box {
    text-align: center;
  }

  .ec-role {
    margin-top: 20px;
  }
}
