///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
インスタグラム最新記事表示ブロック
*/
.ec-instagramRole {
  @include container;
  max-width: $container-maxwidth3;

  @include containerMargin;

  /* ul要素 */
  & &__list {

    // 記事の配置レイアウト
    @include media_sphone {
      @include shelfLayout(3, 0px, 0px);
    }

    @include media_tablet {
      @include shelfLayout(4, 0px, 0px);
    }

    @include media_desktop {
      @include shelfLayout(8, 0px, 0px);
    }

    /* li要素（記事単体） */
    li {
      position: relative;

      // スマホ時は横3列レイアウトなので16個目は収まりが悪いので非表示とします
      @include media_sphone {
        &:nth-child(n+16) {
          display: none;
        }
      }

      a {
        display: block;
        overflow: hidden;
      }

      /* 画像メディア */
      .media_image {
        width: 100%;
        height: 0;
        padding-top: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: .6s;

        // 動画サムネイルの場合
        &.video_thumbnail {

          // スマホ・タブレット時はvideoではなくサムネイル画像を表示する仕様なので再生ボタンアイコンを表示して動画であることを示します
          @media (max-width: $desktop - 1) {
            position: relative;

            &:after {
              font-family: "jeiafont";
              content: "\e939";
              font-size: 3em;
              line-height: 1;
              color: #fff;
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              pointer-events: none;
              z-index: 9;
              opacity: .8;
            }
          }
        }
      }

      &:hover {
        
        .media_image {
          transform: scale(1.1);
          filter: brightness(1.05);
        }
      }

      /* 動画メディア */
      .media_video {

        video {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      /* ポップアップ */
      .popup {
        font-size: 13px;
        padding: 1em;
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        border-radius: 3px;
        filter: drop-shadow(0 5px 5px rgba(0,0,0,0.3));
        pointer-events: none;

        position: absolute;
        bottom: calc(100% + 7px);
        left: 50%;
        width: 100%;
        z-index: 9;
        
        visibility: hidden;
        opacity: 0;
        transform: translate3d(-50%,20px,0);
        transition: .4s;

        /* キャプション */
        .insta_caption {
          font-size: 1em;
        }

        /* いいね件数 */
        .likeCount {
          font-size: .86em;
          text-align: right;

          i {
            font-size: 1.2em;
            margin-right: 0.2em;
            color: #ed4956;
          }
        }

        .insta_caption + .likeCount {
          margin-top: .5em;      
        }

        /* ポップアップくちばし */
        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border: solid 7px transparent;
          border-top-color: #000;
          position: absolute;
          top: 100%;
          left: calc(50% - 7px);      
        }

        //スマホ・タブレット時はポップアップ非表示
        @include media_sphone {
          display: none;
        }

        @include media_tablet {
          display: none;
        }
      }

      /* hoverでポップアップ表示 */
      &:hover .popup {
        visibility: visible;
        opacity: 1;
        transform: translate3d(-50%,0,0);    
      }
    }
  }
}
